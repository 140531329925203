// demo component that creates a text label on the homepage - label created
//  after ~500ms pass from page load.
export class EmptyCart {
  async init() {
    // setTimeout(()=> {
    //   const displayLabelElem = document.createElement('p');
    //   displayLabelElem.innerHTML = 'Babel transpiled JavaScript successfully loaded.';   
    //   document.getElementsByTagName('main')[0].appendChild(displayLabelElem);
    // }, 500);
    // console.log('init empty cart');
      // Handle submit button for ticket items
      jQuery(document).on('click', '.empty-cart', function (e) {
        e.preventDefault();
        console.log('empty cart');
        emptyCart();
        return false;
      });

      function emptyCart(){
        // Save names and quantities to an array
        
          // check if cart is empty before running add rooms
          var action = 'ee_woocommerce_ajax_empty_cart';
          var data = [];
          data.push({name:'action', value : action});    
          jQuery.ajax({
            type: 'POST',
            url: wc_add_to_cart_params.ajax_url, // 
            data: data,
            beforeSend: function (response) {
            },
            complete: function (response) {
               console.log('complete empty cart', response);
            },
            success: function (response) {
              console.log('success empty cart');
              if (response.error && response.messages) {
                // window.location = response.product_url;
                alert(response.messages);
                return;
              }
              jQuery(document.body).trigger('added_to_cart', [response.fragments, response.cart_hash]);
            },
          });
        
      }
  }
}
