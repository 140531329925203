// demo component that creates a text label on the homepage - label created
//  after ~500ms pass from page load.
export class SelectMenus {
  async init() {
    this.addSelectMenus('.woocommerce-ordering, .wpc-filter-content');     
    // Handle reload
    let $this = this;
    jQuery(document).ajaxComplete(function(event,request, settings){ 
      $this.addSelectMenus('.woocommerce-ordering, .wpc-filter-content'); 
    });  
    // Trim labels
    jQuery(".wpc-filter-content .select-selected").html(function(){
      var text= jQuery(this).text().trim().split(" ");
      var first = text.shift();
      return (text.length > 0 ? "<span class='hidden-text'>"+ first + "</span> " : first) + text.join(" ");
    });
  }
 
  addSelectMenus(filterClasses){
    
    const selectMenus = document.querySelectorAll(filterClasses);

      selectMenus.forEach((menu) => {

         // Remove all existing select-selected elements
         const existingSelectedElements = menu.querySelectorAll(".select-selected");
         existingSelectedElements.forEach((existingSelected) => {
             existingSelected.remove();
         });
 
         // Remove all existing select-items elements
         const existingItems = menu.querySelector(".select-items");
         if (existingItems) {
             existingItems.remove();
         }

          const selectElement = menu.querySelector("select");

          if (selectElement) {
              const selectedDiv = document.createElement("DIV");
              selectedDiv.setAttribute("class", "select-selected");
              const selectedOption = selectElement.options[selectElement.selectedIndex].innerHTML.replace(/-/g, "");
              selectedDiv.innerHTML = selectedOption;
              menu.appendChild(selectedDiv);

              const optionsDiv = document.createElement("DIV");
              optionsDiv.setAttribute("class", "select-items select-hide");

              const startPoint = selectElement.classList.contains('orderby') ? 0 : 1;

              for (let j = startPoint; j < selectElement.length; j++) {
                  const optionDiv = document.createElement("DIV");
                  optionDiv.innerHTML = selectElement.options[j].innerHTML;

                  optionDiv.addEventListener("click", function (e) {
                      const originalSelect = this.parentNode.parentNode.querySelector("select");
                      const selectedDiv = this.parentNode.previousSibling;

                      for (let i = 0; i < originalSelect.length; i++) {
                          if (originalSelect.options[i].innerHTML === this.innerHTML) {
                              originalSelect.selectedIndex = i;
                              selectedDiv.innerHTML = this.innerHTML;

                              const sameAsSelected = Array.from(this.parentNode.getElementsByClassName("same-as-selected"));
                              sameAsSelected.forEach((el) => el.removeAttribute("class"));

                              this.setAttribute("class", "same-as-selected");
                              break;
                          }
                      }
                      selectedDiv.click();
                      jQuery(originalSelect).change();
                  });

                  optionsDiv.appendChild(optionDiv);
              }

              menu.appendChild(optionsDiv);

              selectedDiv.addEventListener("click", function (e) {
                  e.stopPropagation();
                  closeAllSelect(this);
                  this.nextSibling.classList.toggle("select-hide");
                  this.classList.toggle("select-arrow-active");
              });
          }
      });

      function closeAllSelect(elmnt) {
          const selectItems = document.getElementsByClassName("select-items");
          const selectSelected = document.getElementsByClassName("select-selected");

          for (let i = 0; i < selectSelected.length; i++) {
              if (elmnt == selectSelected[i]) {
                  continue;
              }
              selectSelected[i].classList.remove("select-arrow-active");
          }

          for (let i = 0; i < selectItems.length; i++) {
              if (elmnt != selectSelected[i]) {
                  selectItems[i].classList.add("select-hide");
              }
          }
      }

      document.addEventListener("click", closeAllSelect);
  }

}
