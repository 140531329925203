//  after ~500ms pass from page load.
export class MobileMenu {
  async init() {
    // setTimeout(()=> {
    // }, 500);
    // console.log('menu foo');
    let toggle_btn = document.querySelector('#site-navigation-menu-toggle');
    let header = document.querySelector('#masthead');

    toggle_btn.addEventListener("click", function() {
      console.log('menu bar');
      header.classList.toggle("toggled");
    });
  }
}