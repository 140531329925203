require('./SerialiseArray');
const Pristine = require("pristinejs");
import { Misc } from './Misc';

export class ProductForm {
  async init() {
    
    if (document.body.classList.contains('single-product')){
      
      const misc = new Misc();
      
      let progressCount = 0;
      let order_total = 0; // 
      let passengerQuantities=[];
      let roomQuantities = []; 
      let deposit_mode = jQuery( 'input[name=ee_deposit_option]:checked' ).val(); // uses jQuery

      // Cache Dom Elements
      let stepper = document.querySelector('.ee_stepper'); 
      let order_details = document.querySelector('.ee_order_details');   
      let deposit_price_wrapper = document.querySelector('.ee_price_deposit'); 
      let full_price = document.querySelector('.ee_price_full .ee_price_amount'); 
      let deposit_price = document.querySelector('.ee_price_deposit .ee_price_amount'); 
      let plus_btns = document.querySelectorAll('.ee_quantity_btn.plus');
      let minus_btns = document.querySelectorAll('.ee_quantity_btn.minus');
      let go_to_tickets_btn = document.querySelector('.ee_go_to_tickets_btn');
      let variations_form = document.querySelector('.variations_form');
      let variations_form_inner = document.querySelector('.single_variation_wrap');
      var element_to_scroll_to = document.querySelector('.summary');
      let passenger_details_heading = document.querySelector('.ee-passenger-details-heading');
      
      // add passenger_details_label
      //console.log('deposit_mode = ' + deposit_mode);
      if(typeof(passenger_details_heading != 'undefined' && passenger_details_heading != null)){
        let passenger_details_label = document.createElement('span');
        passenger_details_label.classList.add('passenger_details_label')
        passenger_details_heading.prepend(passenger_details_label);  
      } 

      // add event listeners

      // Quantity Buttons
      plus_btns.forEach(btn=>{ 
        btn.addEventListener('click', ()=>{ 
          let currentValue = btn.parentNode.querySelector('.qty').value;
          let newValue = parseInt(currentValue)+1;
          let priceRaw = btn.parentNode.querySelector('.qty'); 
          let price = parseInt(priceRaw.dataset.price);
          btn.parentNode.querySelector('.qty').value = newValue;
          let amount = parseInt(price);
          updatePrice(amount);
        }); 
      });

      minus_btns.forEach(btn=>{
        btn.addEventListener('click', ()=>{
          let currentValue = btn.parentNode.querySelector('.qty').value;
          if (parseInt(currentValue) === 0)
            return
          else{
            let newValue = (parseInt(currentValue) == 0) ? 0 : parseInt(currentValue) - 1;
            btn.parentNode.querySelector('.qty').value = newValue;
            
            let priceRaw = btn.parentNode.querySelector('.qty');
            let price = parseInt(priceRaw.dataset.price);
            let amount =  parseInt(price) *-1;
            updatePrice(amount);
          }
        });
      });

      // Set initial deposit mode
      jQuery('input[name=ee_deposit_option]').on("change",function(){
        deposit_mode = jQuery( 'input[name=ee_deposit_option]:checked' ).val();
        toggleDepositMode();  
      });
      
      
      // Refresh Bayna deposit radio button on each step
      jQuery(document).ajaxComplete(function(event,request, settings){ 
        // Deposit radio group gets removed and rebuilt after variation input changes 
        // This code listens for deposits plugin to refresh, then updates the value for the deposit mode 

        // if (request && request.responseText.indexOf("deposits-frontend-wrapper") >= 0){}
        toggleDepositMode();  
      });

      // Handle submit buttons for room items
      if(typeof(go_to_tickets_btn) != 'undefined' && go_to_tickets_btn != null){
        // alert('Element exists!');
        go_to_tickets_btn.addEventListener("click", function(e) { 
          e.preventDefault();
          handleFormSubmit(jQuery(this));
        });
      }

      // Handle submit button for ticket items
      jQuery(document).on('click', '.single_add_to_cart_button:not(.disabled)', function (e) {
        e.preventDefault();
        addTickets(jQuery(this));
        return false;
      });
      
      // handle form submit
      function handleFormSubmit($button){
        // Save names and quantities to an array
        setPassengerQuantities();
        // check if passengers were added
        if(passengerQuantities.length===0){
          alert('Please add some passengers');
        }
        // check if cart is empty before proceeding
        else{ 
          goToStepTwo($button);
        }
      }

      // go to step two
      function goToStepTwo($button){
        // check if cart is empty before proceeding
        var action = 'ee_woocommerce_ajax_check_cart';
        var data = [];
        data.push({name:'action', value : action});    
        jQuery.ajax({
          type: 'POST',
          url: wc_add_to_cart_params.ajax_url, // 
          data: data,
          success: function (response) {
            
            if (response.error && response.messages) {
              // Ask user to empty cart
              alert(response.messages);
              return;
            }
            else{
              // Proceed to next step
              setRoomQuantities($button);
              //addRooms($button)

              goToTickets();
              // Timeout 1
              setTimeout(function(){
                goToNextStep(passengerQuantities,progressCount) 
                // fadeIn();
              }, 125);  
        
              
            }
          },
        });
      }

      // set passenger quantities
      function setPassengerQuantities(){
         // Get passenger quantities for each age group
         let elementsArray = document.querySelectorAll(".ee_ticket_quantities input[type='number']");
         // i loops through elements, j counts elements that have a quantity bigger than 0 
         // j is used to set the index of the quantities array (i wasn't used because sometimes it creates empty elements)
         var j = 0; 
         for(var i=0;i<elementsArray.length;i++){
           if(parseInt(elementsArray[i].value) && parseInt(elementsArray[i].value) > 0){
             let key = elementsArray[i].name;
             let value = parseInt(elementsArray[i].value);
             passengerQuantities[j] = {[key]:value};
             j++;
           }
         }
      }

      // set room quantities
      function setRoomQuantities($button){
        

        var $thisbutton = $button,
        $form = $thisbutton.parents('.summary').find('form.cart'),
        roomsEl = jQuery('.ee_rooms .qty'), // thisbutton.parents('.summary').find
        productEl = jQuery('.ee_rooms .rooms-id');
        
        var data = getFormData( $form);

        var rooms = roomsEl.val();
        var product_id = productEl.val();
        var action = 'ee_woocommerce_ajax_add_to_cart';

        if((data.length>0)&&(rooms>0)){  
          // Store Room Quantites in variable for later use
          
          let deposit = data.find((o) => { return o["name"] === "deposit-mode" });
          var newData = [deposit];
          newData.push({name:'product_id', value : product_id });     
          newData.push({name:'action', value : action});    
          newData.push({name:'quantity', value : rooms});    
          newData.push({name:'deposit-option', value : deposit});    
          
          roomQuantities.push({ button: $thisbutton, data: newData });

          //logRoomQuantities(1)

        }
      }

      // add tickets
      function addTickets($button){
        var $thisbutton = $button,
        $form = $thisbutton.closest('form.cart'),
        data = getFormData($form);

        var form = document.querySelector(".variations_form");

        var pristine = new Pristine(form);
        // check if the form is valid
        var valid = pristine.validate(); 
        
        if(valid===true){
          jQuery.each(data, function (i, item) {
            if (item.name == 'add-to-cart') {
              item.name = 'product_id';
              item.value = $form.find('input[name=variation_id]').val() || $thisbutton.val();
            }
          });
          progressCount++;      
          jQuery(document.body).trigger('adding_to_cart', [$thisbutton, data]);
          ajaxAddTicketItemsToCart($thisbutton, data);
        }else{
          alert("Invalid input data");
        }
      }

      // Handle Ajax Requests

      function ajaxAddRoomItemsToCart(button, data){
        
        var url = wc_add_to_cart_params.ajax_url;

        jQuery.ajax({
          type: 'POST',
          url: wc_add_to_cart_params.ajax_url, 
          data: data,
       
          success: function (response) {
            if (response.error && response.product_url) {
              alert(response.messages);
              return;
            }
            proceedToCheckout();
          },
          error: function (response) {
            proceedToCheckout(); // ?
          },
        });
      } 


      
      function ajaxAddTicketItemsToCart(button, data){
        
        // var url = woocommerce_params.wc_ajax_url.toString().replace('%%endpoint%%', 'add_to_cart');
        // console.log('url ', url);

        jQuery.ajax({
          type: 'POST',
          url: woocommerce_params.wc_ajax_url.toString().replace('%%endpoint%%', 'add_to_cart'),
          data: data,
          beforeSend: function (response) {
            element_to_scroll_to.scrollIntoView({ behavior: 'smooth'});
            fadeOut();
          },
          complete: function (response) {
           goToNextStep(passengerQuantities,progressCount) 
          },
          success: function (response) {
            if (response.error && response.product_url) {
              window.location = response.product_url;
              return;
            }
            jQuery(document.body).trigger('added_to_cart', [response.fragments, response.cart_hash, button]);
          },
        });
      }
      
      function proceedToCheckout()
      { 
        if(jQuery('.added_to_cart.wc-forward')[0]){
          jQuery('.added_to_cart.wc-forward')[0].click();
        }
      }
      
      function goToTickets(){
        //logRoomQuantities(2);
        element_to_scroll_to.scrollIntoView({ behavior: 'smooth'});
        order_details.classList.add('loading'); // Timeout 2 - setTimeout(function(){}, 125);
        createStepCounter(passengerQuantities); 
        
        if(passengerQuantities.length>=2){ 
          stepper.style.display = 'flex';
        }
        // Timeout 3 setTimeout(function(){}, 250); // 500
        order_details.classList.add('hidden');   
        variations_form.classList.add('visible');
        fadeOut();
      }

      /* 
      * Set variant and quantity values for ticket forms
      * called by ajaxAddRoomItemsToCart on first step, 
      * then by ajaxAddTicketItemsToCart on subsequent steps
      */
      function goToNextStep(passengerQuantities,step){
        /**Fff */
        // console.log('next step update deposit');
        // toggleDepositMode ();


        if(step<passengerQuantities.length){ 
          updateStepCounter(step);
          // Timeout 4
          setTimeout(function(){
            prepareTicketFields(passengerQuantities,step);
            fadeIn();
          }, 0);
        }
        else{
          updateStepCounter(passengerQuantities.length);
          if (roomQuantities[0] && roomQuantities[0].data.length > 0){
            ajaxAddRoomItemsToCart(roomQuantities[0].button, roomQuantities[0].data);
          }
          else{
            // No rooms to add. Proceed to checkout
            proceedToCheckout();
          }
        }
      }

      // todo/important: age-group was pa_age-group before switch

      function prepareTicketFields(passengerQuantities,step){

        let variation_el = '#pa_age-group'; // '#age-group'
        let variation = Object.keys(passengerQuantities[step])[0];
        let quantity = Object.values(passengerQuantities[step])[0]; 
        let variations_selector = document.querySelector(variation_el);
        let ticketQuantityField = document.querySelector('.quantity .qty[name="quantity"]');
        // set input values
        variations_selector.value = variation;
        ticketQuantityField.value = quantity;
        // trigger updates for age group variant dropdown & ticket quantity input
        jQuery(variation_el).change(); // using jQuery for age group //
        ticketQuantityField.dispatchEvent(new Event("change"));

        // update heading
        document.querySelector('.passenger_details_label').innerText=variation+" "; // try: let txt = document.createTextNode(variation);
        // open all ticket fields 
        let ticketFields = document.querySelectorAll('.wc-box-office-ticket-fields');
        ticketFields.forEach(ticketField=>{ 
          
          // open accordion item
          ticketField.classList.add('active');
          // Change title to variation name
          let title = ticketField.querySelector(".wc-box-office-ticket-fields-title a");
          title.innerHTML = title.innerHTML.replace(/Ticket/g, variation);
          // prepare inputs & set field
          let variationFields = ticketField.querySelectorAll(".wc-box-office-ticket-fields-body .form-row");
          variationFields.forEach(variationField=>{
          
            let variationFieldLabel = variationField.querySelector("label");
            let variationFieldInput = variationField.querySelector("input");
           
            if(variationFieldInput){
              variationFieldInput.value = '';
              variationFieldInput.autocomplete = 'off';

              if(variationFieldLabel.innerText.includes('Date of birth')){
                variationFieldInput.type = 'date';
              }
            }
          });

        }); 
        
        misc.addDatepickers();

      }
 
      function fadeOut(){
        variations_form.classList.add('loading');
        variations_form_inner.classList.add('fade', 'shrink');
      }

      function fadeIn(){
        // Timeout 5
        setTimeout(function(){
          variations_form_inner.classList.remove('shrink');
        }, 125);
        // Timeout 6
        setTimeout(function(){
          variations_form.classList.remove('loading');
        }, 250);
        // Timeout 7
        setTimeout(function(){
          variations_form_inner.classList.remove('fade');
        }, 375);
      }

      // change deposits plugin setting based on whichever deposit mode was set on first page

      function toggleDepositMode(){
        //console.log('toggle deposit mode');
        //console.log('deposit_mode = ' + deposit_mode)
        if(deposit_mode === 'ee_deposit'){ 
          jQuery('input[name="deposit-mode"][value="check_full"]').prop( "checked", false );
          jQuery('input[name="deposit-mode"][value="check_deposit"]').prop( "checked", true );
        }
        else{
          jQuery('input[name="deposit-mode"][value="check_deposit"]').prop( "checked", false );
          jQuery('input[name="deposit-mode"][value="check_full"]').prop( "checked", true );
        }
        //console.log('deposit_mode = ' + deposit_mode)
        togglePrice();
      }

      function updatePrice(amount){
        if(amount){
          if(deposit_mode === 'ee_deposit'){ 
            order_total = order_total + (amount/2);
            full_price.textContent=order_total;
            deposit_price.textContent=order_total;
          }else{
            order_total = order_total + amount;
            full_price.textContent=order_total;
            deposit_price.textContent=0;
          }
        }
      }

      function togglePrice(){
        if(deposit_mode === 'ee_deposit'){ 
          order_total = order_total/2;
          deposit_price.textContent=order_total;
          deposit_price_wrapper.classList.remove('hidden');
        }
        else{
          order_total = order_total*2;
          deposit_price.textContent=0;
          deposit_price_wrapper.classList.add('hidden');
        }
        full_price.textContent=order_total;
      }

      function createStepCounter(passengerQuantities){
        let counter = 0;
          passengerQuantities.forEach(element => {
            let step_item = document.createElement('div');
            let step_counter = document.createElement('div');
            let step_name = document.createElement('div');
            step_item.classList.add('ee_stepper_item');
            step_counter.className = "ee_step_counter";
            step_name.className = "ee_step_name";
            step_counter.textContent = counter+1;
            step_name.textContent = Object.keys(element)[0] + ' Passenger Details';
            step_item.append(step_counter);    
            step_item.append(step_name);    
            stepper.append(step_item);    
            counter++;
          });
      }
       
      function updateStepCounter(step){
        // set state for steps
        let counter = 0;//-1;
        let step_items = document.querySelectorAll('.ee_stepper_item');
        step_items.forEach(element => {
          if(counter==step)
            element.classList.add('active');  
          if(counter<step){
            element.classList.remove("active");
            element.classList.add('completed');
          }
          counter++;
        });
      }

      // Get inputs from form and return serialised data
      function getFormData($form){
        // Pay attention to nested :not, this excludes the deposit mode that is not checked
        let data = $form.find('input:not([name="product_id"],[name="deposit-mode"]:not(:checked)), select, button, textarea').serializeArrayAll() || 0
        return data
      }

      function logRoomQuantities(num){
        // console.log(num+' roomQuantities.button: ' + roomQuantities[0].button);
        // console.log(roomQuantities[0].button);
        // console.log(num+' roomQuantities.data: ' + roomQuantities[0].data);
        // console.log(roomQuantities[0].data);
      }
      
    }
  }
}