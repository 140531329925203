import { tns } from 'tiny-slider/src/tiny-slider';

export class Sliders {
  async init() {
    document.addEventListener('DOMContentLoaded', () => {
      
      // Select all elements with the class 'ee-featured-products'
      const featuredProductsElements = document.querySelectorAll('.ee-featured-products');

      // Add 'loaded' class to featured products sliders
      featuredProductsElements.forEach(element => {
        element.classList.add('loaded');
      });

      // Wrap each product in a product-container
      const products = document.querySelectorAll('.ee-featured-products .products .product');
      products.forEach(product => {
        const container = document.createElement('div');
        container.classList.add('product-container');
        while (product.firstChild) {
          container.appendChild(product.firstChild);
        }
        product.appendChild(container);
      });

      // Initialize the sliders
      this.initializeSlider('.ee_destination_section .destinations', this.getDestinationSliderConfig());
      this.initializeMultipleSliders('.ee-featured-products .products', this.getFeaturedProductsSliderConfig());
    });
  }

  // Slider Initialization Function
  initializeSlider(selector, config) {
    const container = document.querySelector(selector);
    if (container) {
      console.log('single init');
      tns({
        ...config,
        container,
      });
    } else {
      console.warn(`Slider container for ${selector} not found.`);
    }
  }

  // Initialize Multiple Sliders
  initializeMultipleSliders(selector, config) {
    const sliders = document.querySelectorAll(selector);
    sliders.forEach(slider => {
      console.log('multiple init');
      tns({
        ...config,
        container: slider,
      });
    });
  }

  // Slider Configuration for Destinations
  getDestinationSliderConfig() {
    return {
      slideBy: 1,
      gutter: 0,
      controls: true,
      nav: false,
      loop: false,
      controlsText: ["&#8249;", "&#8250;"],
      autoplay: false,
      items: 1,
      responsive: {
        420: {
          edgePadding: 0,
          items: 1,
        },
        510: {
          edgePadding: 0,
          items: 1,
        },
        600: {
          edgePadding: 0,
          items: 1,
        },
        768: {
          disable: true,
        }
      }
    };
  }

  // Slider Configuration for Featured Products
  getFeaturedProductsSliderConfig() {
    return {
      slideBy: 1,
      gutter: 20,
      controls: true,
      nav: false,
      loop: false,
      controlsText: ["&#8249;", "&#8250;"],
      autoplay: false,
      items: 1,
      responsive: {
        420: {
          edgePadding: 40,
          items: 1,
        },
        510: {
          edgePadding: 60,
          items: 1,
        },
        600: {
          edgePadding: 90,
          items: 1,
        },
        700: {
          edgePadding: 10,
          items: 2,
        },
        1140: {
          edgePadding: 10,
          items: 3,
        },
        1280: {
          edgePadding: 10,
          items: 4,
        }
      }
    };
  }
}

