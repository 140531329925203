

// import { DisplayLabel } from './components/DisplayLabel';
import { EmptyCart } from './components/EmptyCart';
import { Misc } from './components/Misc';

import { SelectMenus } from './components/SelectMenus';
import { MobileMenu } from './components/MobileMenu';
import { Sliders } from './components/Sliders';

import { ProductForm } from './components/ProductForm';
// import { BookingForm } from './components/BookingForm';

let Main = {
  init: async function () {
    // initialize demo javascript component - async/await invokes some 
    // level of babel transformation
    // const displayLabel = new DisplayLabel();
    // await displayLabel.init();

    const selectMenus = new SelectMenus();
    await selectMenus.init();

    const misc = new Misc();
    await misc.init();
    
    const sliders = new Sliders();
    await sliders.init();

    const productForm = new ProductForm();
    await productForm.init();
    
    // const bookingForm = new BookingForm();
    // await bookingForm.init();

    const emptyCart = new EmptyCart();
    await emptyCart.init();

    const mobileMenu = new MobileMenu();
    await mobileMenu.init();

  }
};

Main.init();
