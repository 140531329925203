
  // demo component that creates a text label on the homepage - label created
//  after ~500ms pass from page load.
export class Misc {
  async init() {
      this.addDatepickers();
  }
  async addDatepickers() {
    jQuery('input[type=date]').datepicker({
      dateFormat: 'yy-mm-dd',
      changeMonth: true,
      changeYear: true,
      yearRange: "-100:+0", // last hundred years
    });
    jQuery('input[type=date]').each(function(){
      this.type="text";
    });
  } 
}
 